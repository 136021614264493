import { Speedometer } from "react-bootstrap-icons"
let serverlessLogin = process.env.REACT_APP_SERVERLESS_LOGIN
const dmarc_route = process.env.REACT_APP_DMARC_ROUTE

const Header = (props) => {

  const not_logged_header = (
    <>
      <div className="common-header-branding">
        <h1>NetPillar</h1>
        <a href="/login" className="header-login-text">Log In</a>
      </div>
      <hr></hr>
    </>)

  const logged_header = (
    <>
      <div className="common-header-branding">
        <div style={{marginRight:"auto",visibility:"hidden"}}>Dashboard</div>
        <div style={document.documentElement.clientWidth > 426 ?
          props.drawerOpen ? { marginLeft: "18%" } : { marginLeft: "5%" } : { marginLeft: "0px" }}>
          <h1>NetPillar</h1>
          </div>
          <a href={`/${dmarc_route}/dashboard`} className="header-dashboard-text">Dashboard</a>
         
          
        
      </div>
      <hr></hr>
    </>
  )

  return (

    props.logged_in ? logged_header : not_logged_header

  )

}

export { Header }