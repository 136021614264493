import companyicon from '../static/favicon.png'

const Footer = (props) => {
  return (

    <footer className="nb-footer">
      <div className=".container .container-lg .container-md .container-sm  .container-xl">
        <div className="footer-wrapper">
          <div className="row">
            <div className="col-md-4">
              <div className="company-footer-wrapper">
                <div>
                  <img alt="netpillar companyicon" src={companyicon}></img></div>
                <div>
                  <address>406/250 Pitt St, Sydney NSW 2000</address>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="social-media">
                <div className="social-media-header">Follow Us</div>
                <ul>
                  <li><a href="https://www.facebook.com/NetPillar/" title=""><i className="fa fa-facebook"></i></a></li>
                  <li><a href="https://twitter.com/netpillar" title=""><i className="fa fa-twitter"></i></a></li>
                  <li><a href="https://www.linkedin.com/company/netpillarcomau" title=""><i className="fa fa-linkedin"></i></a></li>
                </ul>
              </div>
            </div>
            <div className="col-md-4 details-netpillar">
              <ol className="details-netpillar">
                <li><a href="/contact-us">Contact us</a></li>
                <li><a href="#">FAQ</a></li>
                {/* <li><a href="https://netpillar.com">What we do</a></li> */}
                <li><a href="#">Security & privacy</a></li>
              </ol>
            </div>
          </div>
            <div className="row">
              <div className="col-sm-6 copyright-container">
                <p>Copyright © {new Date().getFullYear()} Netpillar</p>
              </div>
            </div>
        </div>
       
      </div>
    </footer>

  )

}

export { Footer }
