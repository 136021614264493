import React, { useState, useEffect, lazy, Suspense } from "react";
import StepWizard from "react-step-wizard";
import WizardFooter from "./WizardFooter";
import ButtonComponent from "./ButtonComponent";
import StripeContainer from "../StripeComponent/StripeContainer.js";
import { Overlay } from "../../uihelpers/overlay";
import PaypalComponent from "../PaypalComponent/PaypalComponent";
import { PayPalSubscribeButtons } from "../PaypalComponent/PayPalSubscribeButtons";
import { ButtonGroup } from "react-bootstrap";
import Button from "@restart/ui/esm/Button";
import Product from "../ProductComponent/Product";




const SelectDomain = (props)=>{
  let host = process.env.REACT_APP_BACKEND
  const [authorised_domains,set_authorised_domains] = useState(false)
  const [selected_domain,setselected_domain] = useState("0")
  
  

       const getDomains =  async ()=>{
        const response = await fetch(`${host}/get_authorised_domains`,
            {   credentials:'include',
                method:'post',
            })
            let result = await response.json()
            console.log(result)
            set_authorised_domains(result["data"])
        
    }

      useEffect(()=>{
          getDomains()
      },[])

  
  return (
    <>
    <label>
      Select your primary domain:
      <select className = "form form-control" defaultValue = "0"  onChange = {(e)=>{
          setselected_domain(e.target.value)
          props.setDomain(e.target.value)
        }}>
          
          <option value = {"0"} >Select your Domain</option>
          {authorised_domains?
          authorised_domains.map((item)=> 
          <option value = {item["name"]} >{item["name"]}</option>
          ):<option>Loading your domains....</option>}
          {authorised_domains?
          <option value = {"-1"}>I will set later</option>:""}
          
      </select>
    </label>
  </>
  )
}


const SelectPaymentType = (props)=>{

  const [payment, setPayment] = useState();
  return (
    <>  
             <ButtonComponent
              className="stripe"
              onClick={() => {
              setPayment("stripe");
              props.setSelectedPayment("stripe");
              }}
              title={"Stripe"}
              isChecked={payment === "stripe"}
            />
             
          </>

  )
}
const Wizard = () => {
  const [state, updateState] = useState({  });
  const [product, setProduct] = useState();
  const setInstance = (SW) => updateState({ ...state, SW });
  const [paymentType,setPaymentType] = useState("")
  const [overlaystatus,setoverlaystatus] = useState(false)
  const { SW } = state;

  return (
    <>
    <Overlay activate = {overlaystatus} test_to_show = "Getting subscription data"></Overlay>
      <div className="wizard-wrapper">
        <StepWizard  className="wizard" instance={setInstance}>
              <SelectPaymentType stepName = "payment_selection" setSelectedPayment = {setPaymentType}/>
              <StripeContainer
                stepName={"stripe"}
                setProduct={setProduct}
                product={product}
                setPaymentType = {setPaymentType}
              />
        </StepWizard>
        <WizardFooter SW={SW} 
                      payment={paymentType} 
                      product={product} 
                      setoverlay = {setoverlaystatus} />
      </div>
    </>
  );
};

export default Wizard;
