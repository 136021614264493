import { useState,useCallback } from "react";
import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_BACKEND;

/**
 fixed :
  - no need to JSON.stringify to then immediatly do a JSON.parse
  - don't use export defaults, because default imports are hard to search for
  - axios already support generic request in one parameter, no need to call specialized ones
**/
export const useAxios = () => {
  const [response, setResponse] = useState(undefined);
  const [error, setError] = useState("");
  const [status, setStatus] = useState("");

  const doCall = useCallback(async (params) => {
    try {
      setStatus("loading");
      const result = await axios.request({
        ...params,
        withCredentials: true,
      });
      setResponse(result.data);
      setStatus("succeded");
    } catch (error) {
      setError(error);
    }
  });

  return { doCall, response, error, status };
};
