import "./App.css";
import { initApp } from "./firebase.js";
import React, { useState, useEffect, lazy, Suspense } from "react";
import { AdminInterFace } from "./components/AdminLogins/adminInterface";
import { Header } from './components/header.js'
import { Footer } from './components/footer';
import { Button } from "react-bootstrap";
import { BrowserRouter as Router, Route, Switch, Link, useHistory,Redirect } from 'react-router-dom';
import { VerifyEmail } from './components/UserLogins/verifyemail.js';
import { Faq } from "./components/Support/faq.js"
import Home from "./components/home.js"
import DmarcHome from "./components/dmarchome.js"




const dmarc_path = process.env.REACT_APP_DMARC_ROUTE
const UserInterFace = lazy(() => import("./components/userinterface.js"));

function PrimaryLoader() {
  return (
    <>
      <div className="spinner-border text-primary main-loading-page-spinner">
        <span className="sr-only">Loading...</span>
      </div>
    </>)
}
const DashboardRedirect = (props) => {
  let history = useHistory();
  let timeout = 5
  const [currentCount, setCount] = useState(timeout);
  // a update timer function
  const timer = () => setCount(currentCount - 1);

  useEffect(
    () => {
      if (props.currentUser){
      if (currentCount <= 0) {
        history.push(`/${dmarc_path}/dashboard`)
        return;
      }
      const id = setInterval(timer, 1000);
      return () => clearInterval(id);
    }
  },
    [currentCount,props.currentUser]
  );


  return (
    <>
    {props.currentUser ?
    <div>Redirecting in {currentCount} secs...</div>
    :
    <div>Checking your subscription...</div>
    }
    </>
  )



}


const LoginComponent = (props) => {

 useEffect(()=>
  props.setrefreshLogin(!props.refreshLogin)
 ,[props.logged_in])
  return (
    <>
      {!props.logged_in ?
        <>
          <div id="firebaseui-auth-container"></div>
        </>
        :
        <div>Logout</div>}

    </>
  )
}

function HomeTest(props) {
  useEffect(
    () => props.loadStatus(false)
  )

  return (
    <>
      {props.notfound === false ?
        <>
          <div> Welcome to Secri.io</div>
          <div>
            <button className="btn btn-primary">
              <a href="/login">Login</a>
            </button>
            <Link to="/tools">Email Security Tools</Link>
          </div>
        </>
        :
        <div>
          Page not found
          <div>
            <Link to="/"> Go Home</Link>
          </div>
          <div>{props.children}</div>
        </div>

      }
    </>

  )

}

const LoginParent = (props) => {

  useEffect(()=>{
    props.setrefreshLogin(true)
  },[props.logged_in])
 return(
  <>
  {props.showunverfiedEmailMsg === true ? 
    (
      <>
        <div id="unverified-email">
          <VerifyEmail></VerifyEmail>
        </div>
      </>
    ) :
    <>
      <LoginComponent refreshLogin = {props.refreshLogin} setrefreshLogin={props.setrefreshLogin} logged_in={props.logged_in}></LoginComponent>
      <WhereIam setuserSettingsPage={props.setuserSettingsPage}></WhereIam>
    </>
  }
  </>
  )

}

const WhereIam = (props) => {
  const useQuery = () => new URLSearchParams(new URL(window.location).search);
  let query = useQuery();
  let isModePresent = query.get("mode");
  if (isModePresent) {
    props.setuserSettingsPage(true);
  }
  return (
    <>
      {props.setusersettingPage ? (
        <Button
          onClick={() => (window.location.href = "/")}
          className="home-page-go-home"
        >
          Home
        </Button>
      ) : (
        ""
      )}
    </>
  );
};


function App() {
  let host = process.env.REACT_APP_BACKEND;
  const [logged_in, set_logged_status] = useState(false);
  const [reload, setreload] = useState(false)
  const [data, setdata] = useState(false);
  const [tableClicked, setTableClicked] = useState(false);
  const [drawerOpen, setdrawerOpen] = useState(false);
  const [showunverfiedEmailMsg, setshowunverfiedEmailMsg] = useState(false);
  const [usersettingPage, setuserSettingsPage] = useState(false);
  const [subscriptionStatus, setsubscriptionStatus] = useState(false);
  const [subscriptionDataLoading, setsubscriptionDataLoading] = useState(true)
  const [currentUser, setcurrentUser] = useState(false)
  const [loadStatus, setloadStatus] = useState(true)
  const [refreshLogin, setrefreshLogin] = useState(false)
  const serverlessLogin = process.env.REACT_APP_SERVERLESS_LOGIN


  useEffect(() => {
    
        const checkSession = async () => {
          let response = await fetch(`${serverlessLogin}/api/auth/sessionCheck`, {
            method: "post",
            credentials: "include",
          });
          let result = await response.json();
          if (result.status === "success") {
              set_logged_status(true)
              getSubscriptionStatus()
            
            }   
            if(!logged_in && (window.location.pathname === "/login" || window.location.pathname === `/${dmarc_path}/dashboard`)){
              if (result.status === "failure"){
                initApp({
                  set_logged_status: set_logged_status,
                  setshowunverfiedEmailMsg: setshowunverfiedEmailMsg,
                  setloadStatus: setloadStatus,
                  setsubscriptionStatus:setsubscriptionStatus,
                  setsubscriptionDataLoading:setsubscriptionDataLoading,
                  setcurrentUser:setcurrentUser
        
                });
              }
              
            }   
      }
      if(!logged_in){
        checkSession(); 
      }
      else{
        getSubscriptionStatus()
      }
     
    },[logged_in])
    
    // check subscription if the user is logged in
   

  const getSubscriptionStatus = () => {
    fetch(`${host}/payment/get_user_info`, {
      method: "post",
      credentials: "include",
    })
      .then((response) => response.json())
      .then((user_details) => {
        setloadStatus(false)
        setcurrentUser(user_details)
        set_logged_status(true)
       
      })
   
  
    }


  return (
    <div className="App">
      <Header drawerOpen={drawerOpen} logged_in={logged_in}></Header>
      <div className="body-content">
        {/* if the user is not logged in */}
        {loadStatus && (window.location.pathname === "/login" || window.location.pathname === `/${dmarc_path}/dashboard`)   ?
          <PrimaryLoader setloadStatus={setloadStatus}></PrimaryLoader> : ""
        }
        <>
          <Router>
            <Suspense fallback={<div>Loading...</div>}>
              <Switch>
                
                <Route path ={`/${dmarc_path}/dashboard`}>
                {!logged_in ?
                    <LoginParent refreshLogin = {refreshLogin} setrefreshLogin={setrefreshLogin} setuserSettingsPage={setuserSettingsPage} logged_in={logged_in} ></LoginParent>
                    :
                    <UserInterFace
                      currentUser={currentUser}
                      drawerOpen={drawerOpen}
                      setdrawerOpen={setdrawerOpen}
                      setTableClicked={setTableClicked}
                      logOut={set_logged_status}
                      dataSetter={setdata}
                      setloadStatus={setloadStatus}
                  
                  />
}
                </Route>
                
                <Route exact path={`/${dmarc_path}/knowledgebase`}>
                      <Faq></Faq>
                </Route>

                <Route path={`/${dmarc_path}/adminpanel`}>
                     <AdminInterFace currentUser={currentUser}></AdminInterFace>
                </Route>

                <Route path="/login">
                  {!logged_in ?
                    <LoginParent refreshLogin = {refreshLogin} setrefreshLogin={setrefreshLogin} setuserSettingsPage={setuserSettingsPage} logged_in={logged_in} ></LoginParent>
                    :
                    <DashboardRedirect currentUser = {currentUser} ></DashboardRedirect>
}
                   
                </Route>

                <Route exact path={`/${dmarc_path}`}>
                 <DmarcHome></DmarcHome>
                </Route>
                  {/* for public page */}
                <Route exact  path={`/`}>
                <DmarcHome></DmarcHome>
                 
                </Route>
              
               
                {/* if route not found */}
                <Route path="*" >
                  <>
                    <div className = "notfound">
                    <h1>Not Found</h1>
                    <p>The requested URL was not found on the server. If you entered the URL manually please check your spelling and try again.</p>
                    </div>
                  </>
                </Route>
              </Switch>
            </Suspense>
          </Router>

        </>
      </div>
      {data ? (
        <Footer tableClicked={tableClicked} data={data}></Footer>
      ) : (
        <Footer data={false}></Footer>
      )}
    </div>
  )
}



export default App;
