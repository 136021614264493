
import React from "react";
import { Spinner } from "react-bootstrap";

const Overlay = (props) => {
    

    
    
    return (
        <>
        {props.activate?
        <div className="overlay-wrapper">
          <div className="overlay">
            <div className="overlay-item">
              <Spinner animation="border" variant="primary" role="status">
                <span className="sr-only">Loading  data...</span>
    
              </Spinner>
              <div>{props.text_to_show}</div>
            </div>
          </div>
          </div>
          :""}
          </>
  )
}
export {Overlay}


