import firebase from 'firebase/app';
import * as firebaseui from 'firebaseui';
import 'firebaseui/dist/firebaseui.css';


// need to figure out to have this data send from server
var config = {
  apiKey: "AIzaSyBCZPvwsKL22gpAV7pjcRu34EX7kwGlZi8",
  authDomain: "netpillar-web-dev.firebaseapp.com",
};

const serverlessLogin = process.env.REACT_APP_SERVERLESS_LOGIN
const backend = process.env.REACT_APP_BACKEND
const uiConfig = ({
  signInOptions: [
    firebase.auth.EmailAuthProvider.PROVIDER_ID
  ],
  signInSuccessUrl: '/',
  
  callbacks: {
    signInSuccessWithAuthResult: function(authResult, redirectUrl) {
      // User successfully signed in.
      // Return type determines whether we continue the redirect automatically
      // or whether we leave that to developer to handle.
      if (authResult.additionalUserInfo.isNewUser)
        {
            authResult.user.sendEmailVerification();
        }
      return false;
    },
  },
  tosUrl: '/terms-of-service' // This doesn't exist yet
  
})
const firebaseapp = firebase.initializeApp(config);

// As httpOnly cookies are to be used, do not persist any state client side.
firebase.auth().setPersistence(firebase.auth.Auth.Persistence.NONE);

// This sets up firebaseui
const ui = new firebaseui.auth.AuthUI(firebase.auth())

// This adds firebaseui to the page
// It does everything else on its own
const startFirebaseUI = function(elementId) {
  ui.start(elementId, uiConfig)
}
const initApp = function(statesetter) {
  
  firebase.auth().onAuthStateChanged(function(user) {
    if (user) {
      if(user.emailVerified){
        user.getIdToken().then(function(accessToken) {   
          // Session login endpoint is queried and the session cookie is set.
          // CSRF protection should be taken into account.
          //statesetter.set_logging_process(true)
           statesetter.setloadStatus(true)
              return fetch(
                `${serverlessLogin}/api/auth/sessionLogin`, 
                      {method:"post",credentials:"include",
                            body:JSON.stringify({"user":user.email,"idToken":accessToken})});
        }).then((response) => {
          let jsonresponse = response.json()
          return jsonresponse
          // A page redirect would suffice as the persistence is set to NONE.
          // firebase.auth().signOut();
        }).then((jsonresponse) => {
          // window.location.reload
        if(jsonresponse.status === "success"){
          statesetter.set_logged_status(true)        
        }
        
        }
        )
            
      }
      else{
        statesetter.setloadStatus(false)
        statesetter.setshowunverfiedEmailMsg(true)
        return
      }
           
    } 
    else {
      // User is signed out.
     startFirebaseUI ('#firebaseui-auth-container');
     statesetter.setloadStatus(false)
     document.querySelector("#firebaseui-auth-container > div > form > div.firebaseui-card-header > h1")?
     document.querySelector("#firebaseui-auth-container > div > form > div.firebaseui-card-header > h1").textContent = "Sign in or register with email":
     <div className = "content-centre">
     <div className="spinner-border text-primary"></div>
 </div>
      
      
    }
  }, function(error) {
    console.log(error);
  });
};



  
 

export {startFirebaseUI,initApp,firebase,firebaseapp};

