class Data_analyser {
  constructor(data) {
    // define data
    this.data = data;
    this.results = {
      dkim_auth_pass_count: 0,
      total_email_count: 0,
      dkim_alignment_pass_count:0,
      spf_alignment_pass_count:0,
      spf_auth_pass_count:0,
      all_results_list: [],
      ip_results: [],
      ip_analysed_results: [],
      ip_records: [],
    };
  }

    ip_check = (ip_record) => {
      let result = [-1, false];
      this.results.ip_records.forEach((record, index) => {
        if (
          record.ip === ip_record.ip &&
          record.status[0] === ip_record.spf_alignment &&
          record.status[1] === ip_record.dkim_alignment
        ) {
          result = [index, true];
        }
      });
      return result;
    };

    // params:a function which is able iterate thorough data
    get_results = () => {
      this.data_loop(this.get_all_results);
      console.log(this.results);
      return this.results;
    };

    ip_analyser = (ip_list) => {
      let status_string = ""
      ip_list.forEach((ip_record, index) => {
        let [index_search, result] = this.ip_check(ip_record);
        if (ip_record.spf_alignment === "pass" && ip_record.dkim_alignment === "pass"){
              status_string = "spdp"
        }
        if (ip_record.spf_alignment === "pass" && ip_record.dkim_alignment === "fail") {
          status_string = "spdf"
        }
        if (ip_record.spf_alignment === "fail" && ip_record.dkim_alignment === "pass") {
          status_string = "sfdp"
        }
        if (ip_record.spf_alignment === "fail" && ip_record.dkim_alignment === "fail") {
          status_string = "sfdf"
        }
        if (!result) {
          this.results.ip_records.push({
            ip: ip_record.ip,
            status: [ip_record.spf_alignment, ip_record.dkim_alignment],
            count: ip_record.count,
            auth_dkim:[ip_record.auth_dkim],
            auth_spf:[ip_record.auth_spf],
            status_string:status_string
            // need to add status string here as its very possible that ips wont repeat
            // so in that case we have an error as it is needed for charts logic.
          });
        } else {
          if (ip_record.spf_alignment === "pass" && ip_record.dkim_alignment === "pass") {
            let ip_count = parseInt(ip_record.count);
            this.results.ip_records[index_search].count =
              parseInt(this.results.ip_records[index_search].count) +
              parseInt(ip_count);
            this.results.ip_records[index_search].status_string = "spdp";
           
          }

          if (ip_record.spf_alignment === "fail" && ip_record.dkim_alignment === "pass") {
            let ip_count = parseInt(ip_record.count);
            this.results.ip_records[index_search].count =
              parseInt(this.results.ip_records[index_search].count) +
              parseInt(ip_count);
            this.results.ip_records[index_search].status_string = "sfdp";
        
          }

          if (ip_record.spf_alignment === "pass" && ip_record.dkim_alignment === "fail") {
            let ip_count = parseInt(ip_record.count);
            this.results.ip_records[index_search].count =
              parseInt(this.results.ip_records[index_search].count) +
              parseInt(ip_count);
            this.results.ip_records[index_search].status_string = "spdf";
         
          }

          if (ip_record.spf_alignment === "fail" && ip_record.dkim_alignment === "fail") {
            let ip_count = parseInt(ip_record.count);
            this.results.ip_records[index_search].count =
              parseInt(this.results.ip_records[index_search].count) +
              parseInt(ip_count);
            this.results.ip_records[index_search].status_string = "sfdf";
         
          }
        }
      });
      return this.results;
    };

    // the main loop which takes a function which parses the data
    data_loop = (fn) => {
      // let counter = 0;
      this.data.hits.hits.forEach((hit) => {
        // counter++;
        // console.log(counter);
        this.results.all_results_list.push({ raw_result: hit });

        let record = hit._source.feedback.record;
        // temporarily holds the multiple dkim and spf  domain
        // alignmnet values,resets at each loop
        // but we store the data in instance variable,which in turn gets saved to
        //  all results list,so that we can later allow customers
        //  to loop through individually each record
        let individual_report = {
          email_count: 0,
          dkim_alignment_pass_count: 0,
          spf_alignment_pass_count: 0,
          dkim_auth_pass_count: 0,
          spf_auth_pass_count: 0,
        };
        let adkim_individual_report = [];
        // we will keep invidual reports as well in a list
        let aspf_individual_report = [];
        if (Array.isArray(record)){
        record.forEach((record_row) => {
          fn(
            record_row,
            hit._source.filename,
            individual_report,
            adkim_individual_report,
            aspf_individual_report
          );
        });
        // console.log(this.results)
      }
      else{
          fn(record,hit._source.filename,
            individual_report,
            adkim_individual_report,
            aspf_individual_report)
      }
    })
    };

    // the function  which parses each row of data given by data loop
    get_all_results = (
      record_row,
      filename,
      individual_report,
      adkim_individual_report,
      aspf_individual_report
    ) => {
      let count = record_row.row.count;
      let ip = record_row.row.source_ip;
      this.results.total_email_count += parseInt(count);
      let dkim_alignment = record_row.row.policy_evaluated.dkim;
      let spf_alignment = record_row.row.policy_evaluated.spf;
      individual_report.email_count += parseInt(count);
      individual_report.filename = filename;
      // end individual reports
      // this one for total record of dkim
      if (dkim_alignment === "pass") {
        this.results.dkim_alignment_pass_count += parseInt(count);
        individual_report.dkim_alignment_pass_count += parseInt(count);
      }
      // this one for total record of spf
      if (spf_alignment === "pass") {
        this.results.spf_alignment_pass_count += parseInt(count);
        individual_report.spf_alignment_pass_count += parseInt(count);
      }

      // there can be multiple dkim records, so its a list with domain
      // and results as key value pair
      let auth_dkim = record_row.auth_results.dkim;
      let auth_spf = record_row.auth_results.spf;
      let result_adkim = "fail";
      // not all dkim has array
      // and found records without the auth_results.dkim entry
      // so check for existence first,if not make it fail
      if (auth_dkim) {
        if (Array.isArray(auth_dkim)) {
          let record_dkim_results = [];
          auth_dkim.forEach((record) => {
            let results = { domain: record.domain, result: record.result };
            record_dkim_results.push(results);
            if (record.result === "pass") {
              result_adkim = "pass";
            } else {
              result_adkim = "fail";
            }
          });
          adkim_individual_report.push(record_dkim_results);
        } else {
          result_adkim = auth_dkim.result;
          // console.log(result_adkim);
        }
      } else {
        // no record exists
        result_adkim = "fail";
      }
      // increment the total result count by
      if (result_adkim === "pass") {
        this.results.dkim_auth_pass_count += parseInt(count);
        individual_report.dkim_auth_pass_count += parseInt(count);
        individual_report.adkim_individual_report = adkim_individual_report;
        
      }
      
      // for spf
      let result_aspf = "fail";
      // not all spf is array
      if (auth_spf) {
        if (Array.isArray(auth_spf)) {
          let record_aspf_results = [];
          auth_spf.forEach((record) => {
            let results = { domain: record.domain, result: record.result };
            record_aspf_results.push(results);
            if (record.result === "pass") {
              result_aspf = "pass";
            } else {
              result_aspf = "fail";
            }
          });
          aspf_individual_report.push(record_aspf_results);
        } else {
          result_aspf = auth_spf.result;
        }
      } else {
        result_aspf = "fail";
      }
      // increment the total result count by
      if (result_aspf === "pass") {
        this.results.spf_auth_pass_count += parseInt(count);
        individual_report.aspf_individual_report = aspf_individual_report;
        individual_report.spf_auth_pass_count += parseInt(count);
      }
      this.results.ip_results.push({
        ip: ip,
        count: count,
        dkim_alignment: dkim_alignment,
        spf_alignment: spf_alignment,
        auth_spf:auth_spf,
        auth_dkim:auth_dkim
        
      });
      this.results.all_results_list[
        this.results.all_results_list.length - 1
      ].analysed = individual_report;
      this.results.dkim_alignment_fail_count =
        this.results.total_email_count - this.results.dkim_alignment_pass_count;
      this.results.spf_alignment_fail_count =
        this.results.total_email_count - this.results.spf_alignment_pass_count;
    };
  }

  const makeGeoData =(ip_records) => {
    
    
    const group_by_status_string = (by_country, ip_record) => {
      switch (ip_record.status_string) {
        case "spdp":
          by_country[ip_record.country].hasOwnProperty("dmarc_correct")
            ? (by_country[ip_record.country].dmarc_correct += parseInt(ip_record.count))
            : (by_country[ip_record.country].dmarc_correct = parseInt(ip_record.count));
          break;
        case "sfdp":
          by_country[ip_record.country].hasOwnProperty("dmarc_correct")
            ? (by_country[ip_record.country].dmarc_correct += parseInt(ip_record.count))
            : (by_country[ip_record.country].dmarc_correct = parseInt(ip_record.count));
          break;
        case "spdf":
          by_country[ip_record.country].hasOwnProperty("dmarc_correct")
            ? (by_country[ip_record.country].dmarc_correct += parseInt(ip_record.count))
            : (by_country[ip_record.country].dmarc_correct = parseInt(ip_record.count));
          break;
        case "sfdf":
          by_country[ip_record.country].hasOwnProperty("dmarc_fail")
            ? (by_country[ip_record.country].dmarc_fail += parseInt(ip_record.count))
            : (by_country[ip_record.country].dmarc_fail = parseInt(ip_record.count));
          break;
          default:
            break
      }
    };
  
    const group_ip_by_countries = (dmarc_status) => {
      var by_country = {}
      var country_list = []
      ip_records.forEach((ip_record) => {
        ((country_list, by_country) => {
          if (country_list.includes(ip_record.country)) {
            let result = [false, -1];
            by_country[ip_record.country].forEach((record, index_search) => {
              if (record.status === ip_record.status_string) {
                result = [true, index_search, record.status];
                return result;
              }
            });
            if (result[0]) {
              by_country[ip_record.country][result[1]].count += ip_record.count;
              group_by_status_string(by_country, ip_record);
            } else {
              by_country[ip_record.country].push({
                status: ip_record.status_string,
                count: ip_record.count,
              });
              group_by_status_string(by_country, ip_record);
            }
          } else {
            country_list.push(ip_record.country);
            by_country[ip_record.country] = [
              { status: ip_record.status_string, count: ip_record.count },
            ];
            group_by_status_string(by_country, ip_record);
          }
        })(country_list, by_country);
      });
      let geodata_google = (()=>{
          let geodata = []
          Object.entries(by_country).forEach((item,index)=>{
             if(item[1].hasOwnProperty(dmarc_status)){
              geodata.push([item[0],item[1][dmarc_status]])
             }
              
      })
      return geodata
      })();
      return geodata_google
    };
  
    return {
      group_ip_by_countries_dmarc_correct: 
      group_ip_by_countries("dmarc_correct"),
      group_ip_by_countries_dmarc_fail: 
      group_ip_by_countries("dmarc_fail")
    };
  };
  
  



export { Data_analyser, makeGeoData };
