import React, { useState, useEffect, lazy, Suspense } from "react";
import { useAxios } from "../../../hooks/useAxios.js";
import tick from "../../../static/checked.png";
const Product = (props) => {
  const { title, price, className, onClick, isChecked } = props;

  return (
    <>
      <div className={`product ${className}`} onClick={onClick}>
        {isChecked && <img src={tick} alt="" className="img-checked" />}
        <h2 className="title">{title}</h2>
        <p className="price"> {`${price}$`}</p>
      </div>
    </>
  );
};

export default Product;
